import React from 'react'
import { TopBanner } from '../components/TopBanner'
import { useState } from 'react'
import styled from '@emotion/styled'
import { Button } from '@mui/material'
import Alert from '@mui/material/Alert'
import { navigate } from 'gatsby'
import DangerousIcon from '@mui/icons-material/Dangerous'

import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import CardHeader from '@mui/material/CardHeader'

//import Grid from '@mui/system/Unstable_Grid'
import Grid from '@mui/material/Grid'
import { CardActionArea } from '@mui/material'

import Typography from '@mui/material/Typography'

import LoginIcon from '@mui/icons-material/Login'
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard'
import EuroIcon from '@mui/icons-material/Euro'

import Link from '@mui/material/Link'

interface Props {
  Comp: React.ComponentType<{ value: string }>
}

const isBrowser = typeof window !== 'undefined'

function use() {
  // add error handling
  if (isBrowser) {
    navigate('/search')
  }
}

function create() {
  // add error handling
  if (isBrowser) {
    navigate('/create')
  }
}
function view() {
  // add error handling
  if (isBrowser) {
    navigate('/search?type=view')
  }
}

const Page = () => {
  const authToken: string =
    (isBrowser ? localStorage.getItem('myauth') : '') || ''
  //console.log('auth: ' + authUrl)
  const [errorStatus, setErrorStatus] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  return (
    <>
      <div>
        <TopBanner user="" />
      </div>
      <Container>
        {errorStatus ? (
          <div>
            <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
              Virhetilanne: {errorMessage}
            </Alert>
          </div>
        ) : (
          ''
        )}
        <Typography
          variant="h4"
          component="div"
          align="center"
          mt={2}
          pt={3}
          pb={2}
        >
          Lahjakorttihallinta.mehilainen.fi on muuttanut
        </Typography>

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={5}
          columns={20}
          //sx={{ display: 'none' }}
        >
          <Grid item xs={12} justifyContent="left" alignItems="left">
            <Typography component="div" align="center" mt={2} pt={3} pb={2}>
              Jos olet Hammas Mehiläisestä ja haluat kuitata lahjakortin saldoa
              käytetyksi, voit tehdä sen <Link href="/action">täältä</Link>.
            </Typography>

            <Typography component="div" align="center" mt={2} pt={3} pb={2}>
              Mehiläisen muissa toimipisteissä lahjakorttien käyttö tapahtuu
              11.10.2023 alkaen DH:ssa.
            </Typography>

            <Typography component="div" align="center" mt={2} pt={3} pb={2}>
              Jos käytettävä lahjakortti on ostettu ennen 11.10.2023, et löydä
              sitä suoraan DH:sta, mutta kortti löytyy vanhalla lahjakortin
              koodilla <Link href="/search?type=view">täältä</Link>. Voit hakea
              lahjakortin tiedot <Link href="/search?type=view">täältä</Link> ja
              lahjakortin tiedoista saat poimittua kortin DH ennakkomaksukannan
              numeron, jolla löydät sen DH:sta. Huom. Lahjakortti tulee kirjata
              käytetyksi vain DH:n kautta
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Page

const PageTitle = styled.div`
  max-width: 1400px;
  margin: auto;
  padding: 0 15px 15px;
  text-align: center;
  font-size: 1.5rem;
  font weight: 700;
  padding: 40px;
`

const Container = styled.div`
  max-width: 1400px;
  margin: auto;
  padding: 0 15px 15px;
  text-align: center;
`

const ButtonContainer = styled.div`
  max-width: 1400px;
  margin: auto;
  padding: 0 15px 15px;
  text-align: center;
`
